<template>
    
  <v-card>
    <v-card-title>Audit Trail</v-card-title>
    <v-divider></v-divider>

    <v-card-text>
      <v-data-table
        :headers="auditHeaders"
        :items="auditTable"
        :items-per-page="5"
        :search="search"
        class="elevation-1"
      ></v-data-table>
    </v-card-text>
  </v-card>

</template>

<script>
// -- import something here --
import axios from 'axios';

export default {

  data: () => ({
    search: "",
    auditHeaders: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'ID',
      },
      { text: 'Name', value: 'name' },
      { text: 'Datetime', value: 'DATETIME' },
      { text: 'ACTIVITY', value: 'ACTION' },
    ],
    auditTable: [],
    userList: []
  }),

  mounted() {
    this.getUser();
  },

  methods: {
    getAudit(){
      axios.get('https://atania.app/api/audit/all.php', {
          headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
          }
      })
      .then((response) => {
          for(let k in this.userList){
            for(let l in response.data){
              if(response.data[l].USER_ID == this.userList[k].USER_ID){
                response.data[l].name = this.userList[k].NAME
              }
            }
          }
          
          this.auditTable = response.data;

      })
      .catch(error => {
          console.log(error);
      })
    },

    getUser(){
      axios.get('https://atania.app/api/user/all.php', {
          headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
          }
      })
      .then((response) => {
          this.userList = response.data;
          this.getAudit();

      })
      .catch(error => {
          console.log(error);
      })
    },
  },

};
</script>

<style lang="scss">
@import '~scss/main';


</style>


